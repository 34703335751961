.pagination_container {
  display: flex;
  gap: 30px;
}
.pages {
  display: flex;
  flex-direction: row;
  gap: 25px;
  color: #ac52d1;
}
.btn_no_bg {
  outline: none;
  background-color: transparent;
  border: none;
}
.btn_no_bg:hover {
  cursor: pointer;
}
.text_bold {
  font-weight: 800;
}
.arrow_container {
  display: flex;
}
