.info-tooltip {
    visibility: hidden;
    margin: 0;
    padding: 0;
    z-index: 3;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(30, 29, 29, 0.50);
    display: flex;
    opacity: 0;
    transition: visibility 0s 0.5s, opacity 0.5s linear;
}

.info-tooltip_opened {
    visibility: visible;
    opacity: 3;
    transition: opacity 0.5s linear;
}

.info-tooltip__container {
    margin: 0;
    padding: 64px 41px;
    min-width: 695px;
    min-height: 368px;
    box-sizing: border-box;
    border-radius: 20px;
    border: 1px solid var(--stroke, #9D0095);
    background: #FFF;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    row-gap: 30px;
    position: relative;
    z-index: 1;
}

/* .info-tooltip__sign {
    margin: 0;
    padding: 0;
    object-fit: cover;
    object-position: center;
    max-width: 120px;
    max-height: 120px;
} */

.info-tooltip__heading {
    margin: 0;
    padding: 0;
    text-align: center;
    max-width: 543px;
    font-family: "Rubik";
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #000000;
}

.info-tooltip__button {
    width: 100%;
}

.info-tooltip__close-button {
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    background-color: transparent;
    background-image: url(../../images/close.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    position: absolute;
    top: 48px;
    right: 42px;
    border: none;
    cursor: pointer;
}

/* 
@media screen and (max-width: 690px) {
    .info-tooltip__container {
        padding: 50px 18px 50px;
        min-width: 280px;
        min-height: 308px;
        row-gap: 40px;
    }

    .info-tooltip__heading {
        font-size: 20px;
        line-height: 1.22;
        max-width: 244px;
    }

    .info-tooltip__close-button {
        width: 20px;
        height: 20px;
        background-image: url(../../images/close-white-mobile.svg);
        top: -35px;
        right: 0px;
    }
} */