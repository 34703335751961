.reset-form {
    margin: 0;
    padding: 4vh 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: calc(100% - 55px - 110px);
    min-height: calc(100vh - 55px - 110px);
    box-sizing: border-box;
    position: relative;
}

.reset-form__container {
    margin: 0;
    padding: 40px 70px 70px;
    max-width: 753px;
    width: 50vw;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    row-gap: 50px;
    box-sizing: border-box;
    background-color: #FFF;
    border-radius: 20px;
    border: 1px solid var(--stroke, #9D0095);
}

.reset-form__title {
    margin: 0;
    padding: 0;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
    text-align: center;
}

.reset-form__form {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    row-gap: 10px;
}


.reset-form__input-name {
    margin: 0;
    padding: 0 0 20px 0;
    display: block;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.reset-form__input {
    margin: 0;
    padding: 20px 0 20px 25px;
    width: 100%;
    color: #000;
    font-family: "Rubik";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 20px;
    border: 1px solid #9D0095;
    box-sizing: border-box;
}

.reset-form__input:focus {
    outline: none;
}

.reset-form__input_type_error {
    border-color: #F50000;
}

.reset-form__button {
    margin: 20px 0 0;
    padding: 0;
    height: 75px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #1E1D1D;
    width: 100%;
    box-sizing: border-box;
}

.reset-form__button_type_disabled,
.reset-form__button_type_disabled:hover,
.reset-form__button_type_disabled:active {
    background: linear-gradient(180deg, rgba(206, 142, 192, 0.50) 0%, rgba(194, 139, 228, 0.31) 77.16%);
}

@media screen and (max-width: 1080px) {
    .reset-form {
        padding: 8vh 0;
        height: calc(100% - 55px - 92px);
        min-height: calc(100vh - 55px - 92px);
    }

    .reset-form__container {
        padding: 40px 40px 45px;
        max-width: 680px;
        width: 63vw;
    }
}

@media screen and (max-width: 700px) {
    .reset-form__container {
        padding: 40px 40px 45px;
        max-width: calc(100% - 80px);
        width: calc(100% - 80px);
    }
}

@media screen and (max-width: 520px) {
    .reset-form {
        padding: 0;
        background-color: #FFF;
        height: calc(100% - 55px - 72px);
        min-height: calc(100vh - 55px - 72px);
    }

    .reset-form__container {
        padding: 90px 15px 0;
        max-width: 100%;
        width: 100%;
        border: none;
    }

    .reset-form__title {
        font-size: 20px;
    }

    /* .reset-form__form {
        row-gap: 10px;
    } */

    .reset-form__input-name {
        font-size: 16px;
    }

    .reset-form__input {
        padding: 20px 0 20px 20px;
    }

    .reset-form__button {
        margin: 20px 0 0;
        height: 45px;
        font-size: 16px;
        border-radius: 10px;
    }
}