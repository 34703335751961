.buttons__container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    max-width: 442px;
    padding: 0;
    margin: 0;
}

.buttons__bth {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: "Rubik";
    font-size: 24px;
    line-height: 25px;
    text-align: center;
    color: #000;
    background-color: transparent;
    border: none;
    max-width: 160px;
    row-gap: 15px;
    cursor: pointer;

    position: relative;
}

.buttons__prompt {
    position: absolute;
    bottom: 35px;
    right: -30px;
    background-color: #ffffff;
    border-radius: 0 20px 20px 20px;
    border: 1px solid #D1D1D1;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    display: none;
    margin: 0;
    padding: 10px 5px 10px;
    font-size: 9px;
    line-height: 11px;
    color: #000000;
}

.buttons__bth_type_outside-text {
    position: relative;
}

.buttons__bth-list {
    font-size: 24px;
    position: absolute;
    list-style: none;
    width: 245px;
    height: 95px;
    border-radius: 20px 10px 10px 0;
    border: solid 1px #AD27DC;
    background-color: #FFFCED;
    right: -210px;
    bottom: 67px;
    padding: 7px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 3;
    color: #000;
}

.buttons__bth-list-element:hover {
    font-weight: 500;
}

.buttons__bth-img {
    margin: 0;
    padding: 0;
    position: relative;
    height: 60px;
}

.buttons__bth-main-image {
    border-radius: 10px;
}

.buttons__svg-plus {
    position: absolute;
    bottom: -9px;
    right: 0px;
    border-radius: 50%;
    z-index: 2;
}

.buttons__bth_type_outside-text:hover,
.buttons__bth_type_add-text:hover,
.buttons__bth_type_add-image:hover {
    color: #AD27DC;
}

.buttons__bth:hover .buttons__prompt {
    display: block;
}

/*изменение цвета svg при наведении*/
.buttons__bth:hover .buttons__svg-outside path:nth-of-type(2n - 1),
.buttons__bth:hover .buttons__svg-outside path:nth-of-type(6),
.buttons__bth:hover .buttons__svg-plus-text circle,
.buttons__bth:hover .buttons__svg-plus-text path,
.buttons__bth:hover .buttons__svg-plus-image circle,
.buttons__bth:hover .buttons__svg-plus-image path,
.buttons__bth:hover .buttons__svg-add-image path:nth-of-type(1),
.buttons__bth:hover .buttons__svg-add-image path:nth-of-type(2) {
    stroke: #AD27DC;
}

.buttons__bth:hover .buttons__svg-outside path:nth-of-type(2),
.buttons__bth:hover .buttons__svg-outside path:nth-of-type(4),
.buttons__bth:hover .buttons__svg-add-text path,
.buttons__bth:hover .buttons__svg-add-image path:nth-of-type(3) {
    fill: #AD27DC;
}

.buttons__bth-text {
    margin: 0;
    padding: 0;
}

.buttons__invisible-input {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip-path: inset(0 0 0 0);
    opacity: 0;
}

@media screen and (max-width: 1240px) {
    .buttons__bth-text {
        font-size: 18px;
        max-width: 120px;
    }
}

@media screen and (max-width: 570px) {
    .buttons__bth-text {
        font-size: 14px;
        line-height: 20px;
        max-width: 90px;
    }
}