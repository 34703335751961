.main {
  margin: 0;
  padding: 15px 0 65px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 38px;
  align-items: center;
  position: relative;
  color: #1e1d1d;
  z-index: 2;
}

.main__cat {
  position: absolute;
  top: 77px;
  left: 31px;
  z-index: 1;
  width: 179px;
  height: 154px;
}

.main__title {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 1.18;
  color: #1e1d1d;
  z-index: 2;
  text-align: center;
}

.main__text-box {
  display: flex;
  align-items: center;
  /* font-style: normal; */
}

.main__text-advice {
  margin: 0;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
}

.main__label {
  text-decoration: underline;
  cursor: pointer;
}

.main__label:hover {
  color: #ac52d1;
  opacity: 0.51;
}

.main__label:hover:active {
  color: #7657cd;
  opacity: 1;
}

.main__invisible-input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip-path: inset(0 0 0 0);
  opacity: 0;
}

.search {
  padding-bottom: 25px;
}

@media (max-width: 2080px) {
  .main__title {
    max-width: 420px;
    max-height: 45px;
  }

  .main__text-box {
    padding-top: 30px;
    padding-left: 23px;
    height: 32px;
    display: flex;
    align-items: center;
  }

  .main__text-advice {
    font-size: 30px;
    text-align: center;
    width: 100%;
  }

  .main__label {
    font-size: 30px;
  }

  .main__form {
    display: inline;
    margin-left: auto;
  }
}

@media (max-width: 1460px) {
  .main__cat {
    position: absolute;
    top: 8px;
    left: 80px;
    z-index: 1;
    width: 200px;
    height: 153px;
  }

  .main__text-box {
    padding-top: 125px;
    padding-left: 0;
    height: 24px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .main__text-advice {
    font-size: 30px;
    text-align: center;
    width: 100%;
  }

  .main__label {
    font-size: 30px;
  }
}

@media (max-width: 1180px) {
  .main__text-advice {
    font-size: 27px;
  }

  .main__label {
    font-size: 27px;
  }
}

@media (max-width: 1080px) {
  .main__text-box {
    padding-top: 125px;
    height: 24px;
    width: 100%;
  }

  .main__cat {
    position: absolute;
    top: 8px;
    left: 80px;
    z-index: 1;
    width: 200px;
    height: 153px;
  }

  .main__title {
    max-width: 420px;
    max-height: 40px;
  }

  .main__form {
    display: inline;
  }

  .main__text-advice {
    font-size: 25px;
  }

  .main__label {
    font-size: 25px;
  }
}

@media (max-width: 1020px) and (min-width: 800px) {
  .main__text-advice,
  .main__label {
    font-size: calc(20px + ((100vw - 840px) / 55));
  }
}

@media (max-width: 980px) {
  .main__cat {
    display: none;
  }

  .main__text-box {
    padding-top: 30px;
  }
}

@media (max-width: 800px) {
  .main__form {
    font-size: 20px;
    display: inline-block;
  }

  .main__text-box {
    padding-top: 30px;
    text-align: center;
    width: 100%;
    height: 100%;
  }

  .main__text-advice {
    font-size: 20px;
    text-align: center;
    width: 100%;
  }

  .main__label {
    font-size: 20px;
  }

  .main__title {
    max-width: 256px;
    max-height: 27px;
  }
}

@media (max-width: 520px) and (min-width: 376px) {
  .main__text-box {
    padding-top: 30px;
    text-align: center;
    width: 100%;
    height: 100%;
  }

  .main__cat {
    display: none;
  }

  .main__title {
    max-width: 256px;
    max-height: 27px;
  }

  .main__form {
    font-size: 20px;
    display: inline-block;
  }

  .main__text-advice {
    font-size: 20px;
    text-align: center;
    width: 100%;
  }

  .main__label {
    font-size: 20px;
  }
}

@media (max-width: 375px) {
  .main__text-box {
    padding-top: 30px;
    text-align: center;
  }

  .main__cat {
    display: none;
  }

  .main__title {
    max-width: 252px;
    max-height: 27px;
  }

  .main__form {
    font-size: 20px;
    display: inline-block;
  }

  .main__text-advice {
    font-size: 20px;
    margin-left: 20px;
  }

  .main__label {
    font-size: 20px;
  }
}
