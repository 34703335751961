.wrap_content {
  width: 80%;
  max-width: 77%;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  overflow: none;
  margin-bottom: 50px;
}

.wrap_background_form {
  width: 100%;
  background-color: #ffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  overflow: hidden;
}
.wrap_search_panel {
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border: 1px solid #d09bd5;
  border-radius: 20px;
  z-index: 10;
}

.form_search_panel {
  background-color: #fff;
  width: 100%;
  position: relative;
  display: flex;
  z-index: 3;
  flex-direction: row;
  border-radius: 20px;
  border: none;
}
.wrap_input {
  background-color: #fff;
  flex: auto;
  overflow: auto;
  flex-wrap: wrap;
  overflow-x: none;
  max-width: 100%;
  position: relative;
  display: flex;
  z-index: 3;
  flex-direction: row;
  border-radius: 20px;
  border: none;
}
.search_input {
  font-family: "Rubic", sans-serif;
  font-size: 20px;
  height: 78px;
  border: none;
  border-radius: 20px;
  padding: 0 20px;
  flex-grow: 1;
  outline: none;
  z-index: 3;
  color: #1e1d1d;
}
.search_input::placeholder {
  color: #c0c0c0;
}
.button_form {
  min-width: 82px;
  height: 78px;
  border: none;
  border-radius: 19px;
  outline: none;
  top: 0;
  right: 0;
  display: fixed;
  background-image: linear-gradient(#ce8ec0 100%, #c28be4b0 69%, #c28be4a1 63%);
  cursor: pointer;
  z-index: 3;
}
.button_form:before {
  content: url("/src/images/icons/search_icon.svg");
}
.suggestions_wrap {
  background-color: #fff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  left: 0;
  max-height: 130px;
  overflow-x: hidden;
  overflow-y: auto;
  position: -webkit-sticky;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 4;
  padding-top: 25px;
  transform: translateY(-15px);
}
.suggestions_wrap::-webkit-scrollbar {
  background-clip: padding-box;
  width: 5px;
  padding: 10px;
  height: 80%;
}
.suggestions_wrap::-webkit-scrollbar-track {
  background: #fff;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid #0a0e17;
  border-radius: 20px;
}
.suggestions_wrap::-webkit-scrollbar-thumb {
  background: #0a0e17;
  border: 1px solid #0a0e17;
  border-radius: 20px;
}

.suggestions_wrap > div {
  height: 31px;
  padding: 5px 20px;
  font-size: 20px;
  width: 80%;
  z-index: 30;
}
.suggestions_wrap > div:hover {
  color: #ac52d1;
}

.tag_wrap {
  display: inline-block;
  border-radius: 20px;
  border: none;
}
