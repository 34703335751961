.panel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    /* background-color: #FFFCED; */
    box-sizing: border-box;
    border: none;
    border-radius: 6px;
    height: 36px;
    width: 609px;
    position: relative;
    z-index: 4;
}

.panel__section {
    background-color: #FFFCED;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 10px;
    margin: 0;
    border: none;
    border-right: 0.5px solid #999797;
    height: 100%;
    box-sizing: border-box;
    column-gap: 10px;
}

.panel__section:nth-child(4),
.panel__section:last-of-type {
    border-right: none;
}

.panel__section:last-of-type {
    padding: 0 10px 0 0;
}

.panel__button,
.panel__pseudo-input {
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 24px;
    border-radius: 3px;
    width: 16px;
}

.panel__button:hover,
.panel__invisible-input:hover+.panel__pseudo-input {
    background-color: rgba(192, 192, 192, 0.51);
}

.panel__button_type_pressed,
.panel__button:active,
.panel__invisible-input:checked+.panel__pseudo-input {
    background-color: #FFFFFF;
    box-shadow: inset 0px 1px 1px rgba(115, 114, 112, 0.5);
}

.panel__button_type_in-size {
    background-image: url(../../images/icons/font-size+.svg);
    width: 27px;
}

.panel__button_type_dec-size {
    background-image: url(../../images/icons/font-size-.svg);
    width: 25px;
}

.panel__option {
    width: 100%;
    border-bottom: 0.5px solid #C0C0C0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    box-sizing: border-box;
    min-height: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: end;
    cursor: pointer;
}

.panel__pseudo-option {
    padding: 5px 9px 0px;
    box-sizing: border-box;
}

.panel__selector {
    margin: 0;
    padding: 0;
    width: 70px;
    height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}

.panel__container {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.panel__invisible-input {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip-path: inset(0 0 0 0);
    opacity: 0;
}

.panel__pseudo-input_type_bold {
    background-image: url('../../images/icons/bold.svg');
}

.panel__pseudo-input_type_italic {
    background-image: url('../../images/icons/italic.svg');
}

.panel__pseudo-input_type_underline {
    background-image: url('../../images/icons/underline.svg');
}

.panel__pseudo-input_type_line-through {
    background-image: url('../../images/icons/line-through.svg');
}

.panel___button_type_color {
    position: relative;
}

.panel___button_type_text-color {
    background-image: url(../../images/icons/text-color.svg);
    width: 34px;
}

.panel___button_type_stroke-color {
    background-image: url(../../images/icons/stroke-color.svg);
    width: 33px;
}

.panel___button_type_back-color {
    background-image: url(../../images/icons/background-color.svg);
    width: 44px;
}

.panel___button_type_opacity {
    background-image: url(../../images/icons/opacity.svg);
    width: 24px;
    position: relative;
}

.panel__pseudo-input_type_start {
    background-image: url('../../images/icons/text-start.svg');
    width: 24px;
}

.panel__pseudo-input_type_center {
    background-image: url('../../images/icons/text-center.svg');
    width: 24px;
}

.panel__pseudo-input_type_end {
    background-image: url('../../images/icons/text-end.svg');
    width: 24px;
    box-sizing: content-box;
}

.panel__button_type_reset {
    background-image: url(../../images/icons/reset.svg);
    width: 24px;
    padding: 0;
    box-sizing: content-box;
}

.panel__btn-reset-message {
    display: none;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #101010;
    margin: 0;
    padding: 16px 7px;
    background-color: #FFFFFF;
    border: 1px solid #AD27DC;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 0px 20px 20px 20px;
    position: absolute;
    top: 35px;
    right: -250px;
    transition: all .3s;
    transition-delay: .3s, 0s;
    opacity: 0;
}

.panel__btn-reset:hover+.panel__btn-reset-message {
    display: inline-block;
    opacity: 1;
}

@media screen and (max-width: 700px) {
    .panel {
        flex-wrap: wrap;
        max-width: 331px;
    }

    .panel__section {
        border: none;
    }
}