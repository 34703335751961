* {
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
}

.page {
  overflow: hidden;

  max-width: 1600px;
  min-width: 320px;
  margin: 0 auto 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  /* text-rendering: optimizeLegibility; */
  background-color: #fcfdb5;
  background-size: contain;
}

.btn {
  background: linear-gradient(
    180deg,
    #ce8ec0 0%,
    rgba(194, 139, 228, 0.63) 77.16%
  );
  border-radius: 20px;
  cursor: pointer;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-size: 20px;
  font-weight: 400;
  min-height: 74px;
}

.btn:hover {
  background: linear-gradient(
    180deg,
    rgba(148, 27, 219, 0.662831) 39.06%,
    rgba(171, 95, 218, 0.63) 100%
  );
}

.btn:active {
  background: rgba(148, 27, 219, 0.76);
}
.btn_blocked {
  background: grey;
}
.btn_blocked:hover {
  background: grey;
}

@media screen and (max-width: 570px) {
  .btn {
    min-height: 44px;
  }
}
