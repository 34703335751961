.authentication__input-prompt {
    padding: 5px 0 0;
    margin: 0;
    width: 100%;
    min-height: 34px;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
    box-sizing: border-box;
}

.authentication__input-prompt_type_normal {
    color: #665D5D;
}

.authentication__input-prompt_type_error {
    color: #F50000;
}

@media screen and (max-width: 1080px) {
    .authentication__input-prompt {
        font-size: 16px;
    }
}

@media screen and (max-width: 520px) {
    .authentication__input-prompt {
        font-size: 12px;
    }
}