.meme {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  row-gap: 10px;
  max-width: 440px;
  padding-bottom: 0;
}

.meme__image {
  position: relative;
  height: inherit;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
}

.meme__image-hover {
  background: linear-gradient(
    0deg,
    rgba(66, 14, 92, 0.36),
    rgba(66, 14, 92, 0.36)
  );
  position: absolute;
  top: 0;
  border-radius: 20px;
  height: 100%;
  width: inherit;
  z-index: 1;
  opacity: 0;
  transition: 0.1s ease;
}

.meme__image-hover:hover {
  transition: 0.1s ease;
  opacity: 1;
  cursor: pointer;
}

.meme:hover > .meme__create-btn {
  background-color: #fcff70;
}

.meme__create-btn:active {
  background-color: #f8fd22;
}

.meme__like {
  position: absolute;
  top: 25px;
  left: 25px;
  z-index: 2;
  cursor: pointer;
}

.meme__create-btn {
  color: #1e1d1d;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  background-color: #fdffa1;
  width: 184px;
  height: 54px;
  border: none;
  border-radius: 8px;
  box-shadow: 2px 5px 9px rgba(0, 0, 0, 0.64);
  cursor: pointer;
  z-index: 3;
  position: absolute;
  bottom: 15px;
}

.meme__create-btn:hover {
  background-color: #f8fd22;
}

.meme__create-btn:hover ~ .meme__image-hover {
  opacity: 1;
}

.meme__tags-container {
  height: 96px;
  position: relative;
  width: 100%;
}

.meme__tags {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  column-gap: 5px;
  align-items: flex-start;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #1e1d1d;
  align-self: flex-start;
  padding: 0;
  max-height: 20px;
  overflow: hidden;
  width: 100%;
}

.meme__tags_more {
  max-height: 96px;
  position: absolute;
  top: 0;
  max-width: 457px;
  /* width: 80%; */
}

.meme__bth-see-more {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #1e1d1d;
  cursor: pointer;
}
.meme__box-image {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 360px;
  width: 453px;
}
@media (max-width: 1480px) and (min-width: 1081px) {
  /* .meme {
    max-width: 453px;
    max-height: 360px;
  } */
  .meme__box-image {
    max-width: 453px;
    max-height: 360px;
  }
}

@media (max-width: 1080px) and (min-width: 521px) {
  /* .meme {
    max-width: 440px;
    max-height: 360px;
  } */
  .meme__box-image {
    max-width: 440px;
    max-height: 360px;
  }
}
@media (max-width: 520px) and (min-width: 480px) {
  /* .meme {
    max-width: 440px;
    max-height: 360px;
  } */
  .meme__box-image {
    max-width: 440px;
    max-height: 360px;
  }
}
@media (max-width: 479px) and (min-width: 376px) {
  /* .meme {
    max-width: 345px;
    max-height: 300px;
  } */
  .meme__box-image {
    width: 345px;
    height: 300px;
  }
}

@media (max-width: 375px) {
  /* .meme {
    max-width: 345px;
    max-height: 300px;
  } */
  .meme__box-image {
    height: 220px;
    width: 270px;
  }
}
