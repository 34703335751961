.authentication__input-valid {
    background-image: url("../../images/authenticationPage/input-checked.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 35px;
    height: 35px;
    position: absolute;
    top: 43px;
    right: 20px;
    display: none;
}

.authentication__input-valid-visible {
    display: block;
}