.memesbox {
  margin: 0;
  padding: 0 0 95px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 40px;
  align-items: center;
  position: relative;
}

.memesbox__container {
  margin: 0;
  padding: 0 30px 0;
  width: 100%;
  display: grid;
  justify-content: space-around;
  z-index: 2;
  grid-template-columns: repeat(auto-fit, minmax(423px, 453px));
  column-gap: 30px;
  row-gap: 25px;
  box-sizing: border-box;
}

.memesbox__btn-show-more {
  max-width: 457px;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  color: #1e1d1d;
  width: 80%;
}

.memesbox__up {
  margin: 0;
  padding: 0;
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 6px;
  text-decoration: none;
  cursor: pointer;
  z-index: 2;
  position: fixed;
  bottom: 50px;
  right: 30px;
  background-color: #fcfdb5;
  border-radius: 50%;
  border: 2px solid #9d0095;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  width: 101px;
  height: 101px;
}

.memesbox__up:hover {
  background-color: #fdff9b;
}

.memesbox__up:active {
  background-color: #fcff5e;
}

.memesbox__up_type_fixed {
  display: flex;
}

.memesbox__up_type_absolute {
  position: absolute;
  bottom: 75px;
  right: 30px;
}

.memesbox__up-arrow {
  padding: 14px 0 0;
  height: 25px;
  width: 32px;
}

.memesbox__up-text {
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #1e1d1d;
}
.tab_container {
  align-items: center;
  display: grid;
  justify-content: space-between;
  padding: 0 47px 20px;
  grid-template-columns: 15fr 1fr;
  height: 70px;
}
.box {
  width: 100%;
  box-sizing: border-box;
  display: grid;
}
.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-start;
}
.tabs:first-child {
  padding: 0;
}
.tab_button {
  padding-right: 30px;
  font-size: 25px;
  background-color: inherit;
  border: none;
  font-family: inherit;
  text-align: center;
  text-decoration: none;
}
.tab_button:hover {
  color: #ac52d1;
  cursor: pointer;
}
.tab_isOn {
  color: #ac52d1;
}

.burger_icon {
  justify-self: end;
  position: relative;
}
.svg:hover {
  filter: invert(54%) sepia(92%) saturate(3146%) hue-rotate(245deg)
    brightness(83%) contrast(97%);
}

@media (max-width: 1080px) and (min-width: 200px) {
  .memesbox__container {
    grid-template-columns: none;
  }
}
@media (max-width: 1480px) and (min-width: 1081px) {
  .box {
    padding: 31px, 29px, 43px, 0;
  }

  /* .tabs {
    padding: 30px 0 30px 87px;
  } */
}

@media (max-width: 1080px) and (min-width: 865px) {
  .memesbox__up {
    right: 90px;
  }
  .box {
    padding: 83px, 74px, 115px, 0;
  }
  .tab_container {
    height: 60px;
  }
  .tabs {
    padding: 83px;
  }
}
@media (max-width: 864px) and (min-width: 521px) {
  .box {
    padding: 83px, 74px, 115px, 0;
  }
  .tab_container {
    height: 60px;
  }

  .tab_button {
    font-size: 16px;
  }
}
@media (max-width: 610px) and (min-width: 376px) {
  .box {
    padding: 83px, 74px, 115px, 0;
  }
  .tab_container {
    height: 60px;
  }

  .tab_button {
    padding: 0;
    font-size: 16px;
  }
  .tabs {
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
  }
}
@media (max-width: 430px) and (min-width: 376px) {
  .tabs {
    row-gap: 7px;
    column-gap: 7px;
  }
}
@media (max-width: 520px) and (min-width: 376px) {
  .box {
    padding: 42px, 40px, 85px, 0;
  }

  .tabs {
    /* padding: 42px; */
  }
}

@media (max-width: 375px) {
  .box {
    /* padding: 15px, 15px, 77px, 0; */
  }
  /* .tab_container {
    padding: 15px, 15px, 77px, 0;
  } */

  .tabs {
    flex-wrap: wrap;
    row-gap: 7px;
    column-gap: 7px;
    justify-content: start;
  }
  .tab_button {
    font-size: 16px;
    padding: 0;
  }
}
