.authentication {
    margin: 0;
    padding: 3vh 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: calc(100% - 55px - 110px);
    min-height: calc(100vh - 55px - 110px);
    box-sizing: border-box;
}

.authentication__container {
    margin: 0;
    padding: 35px 35px;
    max-width: 620px;
    min-width: 42vw;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    background-color: #FFF;
    border-radius: 20px;
    border: 1px solid var(--stroke, #9D0095);
    row-gap: 10px;
}

.authentication__title {
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
    text-align: center;
}

.authentication__form {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
}

.authentication__label {
    position: relative;
}

.authentication__input-name {
    margin: 0;
    padding: 0 0 10px 0;
    display: block;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.authentication__input {
    margin: 0;
    padding: 20px 0 20px 25px;
    width: 100%;
    color: #000;
    font-family: "Rubik";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 20px;
    border: 1px solid #9D0095;
    box-sizing: border-box;
}

.authentication__input:focus {
    outline: none;
}

.authentication__input_type_error {
    border-color: #F50000;
}

.authentication__button {
    margin: 0;
    padding: 0;
    height: 60px;
    min-height: 60px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #1E1D1D;
    width: 100%;
    box-sizing: border-box;
}

.authentication__button_type_disabled,
.authentication__button_type_disabled:hover,
.authentication__button_type_disabled:active {
    background: linear-gradient(180deg, rgba(206, 142, 192, 0.50) 0%, rgba(194, 139, 228, 0.31) 77.16%);
}

.authentication__checkbox-container {
    margin: 0;
    padding: 5px 0 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
    cursor: pointer;
}

.authentication__invisible-checkbox {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip-path: inset(0 0 0 0);
}

.authentication__pseudo-checkbox {
    margin: 0;
    padding: 0;
    width: 30px;
    height: 30px;
    border: 1px solid #D9D9D9;
    background-color: transparent;
}

.authentication__invisible-checkbox:checked+.authentication__pseudo-checkbox {
    background-image: url('../../images/authenticationPage/checkbox.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.authentication__checkbox-name {
    display: block;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #000000;
}

.authentication__colored-text {
    color: #7656CD;
}

.authentication__link {
    font-size: 16px;
    margin: 0;
    padding: 0 0 10px;
    text-decoration: none;
}

.authentication__login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    row-gap: 30px;
    width: 100%;
}

.authentication__login-text {
    margin: 0;
    padding: 5px 0 0;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.authentication__login-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 25px;
}

.authentication__icon {
    transform: scale(1);
    transition: transform 0.2s linear;
    cursor: pointer;
}

.authentication__icon:hover {
    transform: scale(1.083);
}

.google {
    background-image: url("../../images/authenticationPage/google.svg");
    width: 60px;
    height: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.authentication__temporarily-inactive {
    position: relative;
}

.authentication__temporarily-inactive:hover .prompt {
    display: flex;
}

@media screen and (max-width: 1080px) {
    .authentication {
        padding: 9vh 0;
        height: calc(100% - 55px - 92px);
        min-height: calc(100vh - 55px - 92px);
    }

    .authentication__container {
        padding: 40px 40px;
        width: 63vw;
        max-width: calc(100% - 150px);
    }

    .authentication__title {
        font-size: 25px;
    }

    .authentication__input-name {
        font-size: 20px;
    }

    .authentication__input {
        padding: 28px 0 28px 20px;
        font-size: 20px;
    }

    .authentication__button {
        height: 75px;
        min-height: 75px;
    }

    .authentication__link {
        font-size: 20px;
    }

    .authentication__checkbox-name {
        font-size: 18px;
    }
}

@media screen and (max-width: 700px) {
    .authentication__container {
        padding: 40px 40px;
        max-width: calc(100% - 80px);
        width: calc(100% - 80px);
    }
}

@media screen and (max-width: 520px) {
    .authentication {
        padding: 0;
        background-color: #FFF;
        height: calc(100% - 55px - 72px);
        min-height: calc(100vh - 55px - 72px);
    }

    .authentication__container {
        padding: 40px 15px 70px;
        max-width: 100%;
        width: 100%;
        border: none;
    }

    .authentication__title {
        font-size: 16px;
    }

    .authentication__input-name {
        font-size: 14px;
    }

    .authentication__input {
        padding: 14px 0 14px 20px;
        font-size: 14px;
        border-radius: 10px;
    }

    .authentication__button {
        height: 45px;
        min-height: 45px;
        font-size: 16px;
        border-radius: 10px;
    }

    .authentication__link {
        font-size: 14px;
    }

    .authentication__checkbox-name {
        font-size: 12px;
    }

    .authentication__login-text {
        font-size: 16px;
    }

    .authentication__login-icons {
        column-gap: 15px;
    }

    .authentication__icon {
        width: 40px;
        height: 40px;
    }

    .authentication__icon:hover {
        transform: scale(1.125);
    }
}