.prompt {
  display: none;
  border-radius: 0px 20px 20px 20px;
  border: 1px solid #d1d1d1;
  background-color: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  bottom: -30px;
  left: 50%;
  align-items: center;
  justify-content: center;
  color: #000;
  min-width: 70px;
  min-height: 30px;
  padding: 8px;
  box-sizing: border-box;
  z-index: 50;
}

.prompt > span {
  /* white-space: nowrap; */
  font-family: "Rubik";
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
