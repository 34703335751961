.main-editor {
  padding: 20px 30px 20px;
  width: 100%;
  box-sizing: border-box;
}

.editor {
  padding: 81px 0 0px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  min-height: 556px;
}

.editor__canvas {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 675px; */
}

.editor__image {
  font-weight: 500;
  font-size: 70px;
  line-height: 83px;
  white-space: pre-wrap;
  word-break: break-all;
  border-radius: 20px;
}

.editor__box {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 42px;
  align-items: center;
  width: calc(100% - 675px);
}

.editor__text-form {
  position: absolute;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.editor__color {
  margin-bottom: 20px;
}

.editor-meme__nav {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-left: 30px;
}

.editor-meme__link {
  font-weight: 300;
  font-size: 30px;
  line-height: 36px;
  color: #1E1D1D;
  text-decoration: none;
  margin-right: 5px;
}

.editor-meme__span {
  margin-left: 5px;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
}

.icon {
  width: 25px;
}

.icon-size {
  width: 40px;
  height: 40px;
}

.editor__text-control-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 700px;
}

.editor__btn_type_create-mem {
  width: 500px;
  color: #1E1D1D;
  font-size: 20px;
  line-height: 1.2;
}

@media screen and (max-width: 1240px) {
  .editor__btn_type_create-mem {
    width: 400px;
  }
}

@media screen and (max-width: 1140px) {
  .main-editor {
    padding-right: 6.5%;
    padding-left: 6.5%;
    padding-bottom: 75px;
  }

  .editor {
    flex-direction: column;
    row-gap: 40px;
    padding-top: 96px;
  }

  .editor__btn_type_create-mem {
    width: 500px;
  }

  .editor__box {
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .editor {
    padding-top: 132px;
  }
}

@media screen and (max-width: 570px) {
  .main-editor {
    padding-bottom: 25px;
  }

  .editor {
    padding-top: 122px;
    row-gap: 20px;
  }

  .editor__btn_type_create-mem {
    font-size: 18px;
    width: 77%;
    height: 44px;
  }
}