.btn_no_bg {
  outline: none;
  background-color: transparent;
  border: none;
}
.btn_no_bg:hover {
  cursor: pointer;
}
.search_component {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #d09bd5;
  width: 100%;
}

.search_input {
  height: 42px;
  position: relative;
  z-index: 1;
  width: 100%;
  border-radius: 20px;
  border: none;
}
.search_input:focus {
  outline: none;
}
.wrap_search {
  width: 100%;
  display: flex;
  border-radius: 19px;
  border: 1px solid transparent;
}
.text_style {
  font-size: 16px;
  font-weight: 400px;
  padding: 0 20px;
}

.search_button {
  transform: translateX(1.5px);
  min-width: 51px;
  height: 44px;
  border: 1px solid;
  border-radius: 20px;
  outline: none;
  top: 0;
  right: 0;
  display: fixed;
  cursor: pointer;
  z-index: 3;
  border: 1px solid #d09bd5;
}
.search_button::before {
  content: url("/src/images/icons/lupa.svg");
  position: absolute;
  top: 8px;
  left: 10px;
}
@media screen and (max-width: 1480px) {
  .memes_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
  }

  .saved_meme_img {
    width: 100%;
    height: 100%;
    aspect-ratio: 284/226;
  }

  .search_input {
    /* width: 131px; */
  }
}

@media screen and (max-width: 1080px) {
  .search_button {
    top: 2px;
  }
  .wrap_search {
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .search_input {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    width: 100%;
  }

  .search_button {
    top: 2px;
  }
  @media screen and (max-width: 375.5px) {
    .search_component {
      border-radius: 10px;
      border: 1px solid #d09bd5;
      width: 100%;
      height: 59px;
    }
    .search_input {
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
      width: 80%;
    }

    .search_button {
      border-radius: 10px;
      height: 104%;
      min-width: 59px;
      transform: translate(1px, 0.4px);
      min-width: 59px;
    }
    .search_button::before {
      top: 16px;
      left: 14px;
    }
  }
}
