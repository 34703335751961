.footer {
  min-height: 110px;
  background-color: #FFFFDA;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 28px 60px;
  box-sizing: border-box;
}

.footer__link-team {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  color: #000000;
}

.footer__link-team--active {
  color: #AC52D1;
}

.footer__link-team:hover {
  color: #1083DD;
}

@media screen and (max-width: 1080px) {
  .footer {
    min-height: 92px;
  }
}

@media screen and (max-width: 520px) {
  .footer {
    min-height: 72px;
  }
}