.textarea__box {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: double dashed dashed;
    border-width: 10px 1px 1px;
}

.textarea__box_type_ungrabbing {
    border-style: dashed;
    border-width: 2px;
    padding-top: 8px;
}

.textarea__box_type_grabbing {
    cursor: grab;
}

.textarea__box_type_grabbing:active {
    cursor: grabbing;
}

.textarea__text {
    margin: 0;
    background-color: transparent;
    border: none;
    box-sizing: border-box;
    overflow: hidden;
    line-height: 1.2;
    text-align: center;
    padding-top: 12px;
    -webkit-text-fill-color: transparent;
}

.textarea__text:focus {
    outline: none;
}

::-webkit-resizer {
    display: none;
}

.textarea__resizer {
    background-image: url("../../images/editor/change-size.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 25px;
    width: 25px;
    z-index: 2;
    position: absolute;
    bottom: 0px;
    right: 0px;
    pointer-events: none;
}

.textarea__text::-webkit-input-placeholder,
.textarea__text::-moz-placeholder,
.textarea__text::-ms-input-placeholder,
.textarea__text::placeholder {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 1.2;
    -webkit-text-fill-color: #FFFFFF;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin: 0;
    text-align: center;
}

.textarea__panel {
    margin: 0;
    padding: 0;
    position: absolute;
    background-color: #FFFCED;
}

.textarea__container {
    margin: 0;
    padding: 0;
    position: relative;
}

.textarea__delete-text {
    margin: 0;
    padding: 0;
    border: none;
    background-image: url("../../images/editor/delete-text.svg");
    background-position: center;
    background-repeat: no-repeat;
    height: 19px;
    width: 19px;
    position: absolute;
    right: 2px;
    top: 2px;
    border-radius: 50%;
    z-index: 2;
    cursor: pointer;
}

@media screen and (max-width: 700px) {

    .textarea__text::-webkit-input-placeholder,
    .textarea__text::-moz-placeholder,
    .textarea__text::-ms-input-placeholder,
    .textarea__text::placeholder {
        font-size: 30px;
    }
}

@media screen and (max-width: 570px) {

    .textarea__text::-webkit-input-placeholder,
    .textarea__text::-moz-placeholder,
    .textarea__text::-ms-input-placeholder,
    .textarea__text::placeholder {
        font-size: 25px;
    }
}